import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 33%;
`;

const TextField = styled.textarea`
  height: 100%;
  width: 100%;
`;

function App() {
  const [inputText, setInputText] = useState('Input');
  const [funcText, setFuncText] = useState('Function');
  const [outputText, setOutputText] = useState('Output');

  const onFuncTextInput = (e) => {
    setFuncText(e.target.value);
    var funcOutput = "";
    try {
      funcOutput = Function(e.target.value)(inputText);
    } catch (err) {
      funcOutput = err;
    }
    setOutputText(funcOutput);
  }
  
  return (
    <Container>
      <Column>
        Input
        <TextField id="input" name="input" value={inputText} onInput={e => setInputText(e.target.value)} ></TextField>
      </Column>
      <Column>
        Function
        <TextField id="function" name="function" value={funcText} onInput={onFuncTextInput} ></TextField>
      </Column>
      <Column>
        Output
        <TextField id="output" name="output" value={outputText} readOnly ></TextField>
      </Column>
    </Container>
  );
}

export default App;
